<template>
  <div class="mini-tool-entry layout-vertical layout-center" v-if="entry">
    <el-image class="image-logo layout-inflexible" :src="$prepareImageUrl(entry.logo)"></el-image>
    <div class="font-size-extra-small margin-top-small">{{entry.name}}</div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "MiniToolEntry",
  mixins: [httpapi],
  props: {
    entry: Object,
  }
}
</script>

<style scoped>

.image-logo {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
}

.mini-tool-entry {
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  width: 48px;
}

.mini-tool-entry:hover {
  background-color: #f1f1f1;
  color: #1b1b1b;
}

</style>