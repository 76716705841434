<template>
  <div class="layout-horizontal" style="width: calc(100vw - 420px);">
    <div class="layout-inflexible">
      <div class="minitool-list layout-horizontal layout-wrap padding-top-large">
        <mini-tool-entry
            v-for="entry in miniToolEntries"
            :key="entry.id"
            :entry="entry"
            class="minitool-entry"
            @click.native="clickEntry(entry)"
        ></mini-tool-entry>
      </div>
    </div>
    <div class="layout-flexible" style="overflow: hidden;">
      <iframe
          ref="iframe"
          class="iframe-mini-tool"
          :src="src"
          frameborder="0"
          style="height: 100%; width: 100%"
          scrolling="no"
      ></iframe>
    </div>
  </div>
</template>

<script>
import MiniToolEntry from "@/pages/minitool/basic/MiniToolEntry";
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "MiniToolPanel",
  mixins: [httpapi],
  components: {MiniToolEntry},
  props: {
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {

      busyLoadingCode: 0,

      miniToolEntries: [],

      currentMiniToolEntry: null,

      src: null
    }
  },
  computed: {
    inputParams() {
      const {organizationId, userId} = this;
      return {
        organizationId,
        userId
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.miniToolEntries = null;
        this.currentMiniToolEntry = null;
        this.loadMiniToolEntries();
      },
      immediate: true,
    },
    currentMiniToolEntry: {
      handler: function (currentEntry) {
        let src = null;
        if (currentEntry?.webIndexPage) {
          src = currentEntry.webIndexPage;
          if (currentEntry.webIndexPage.indexOf("?") > 0) {
            src += "&";
          } else {
            src += "?"
          }
          src += "userId=" + this.userId + "&organizationId=" + this.organizationId;
        }
        this.src = src;
      },
      immediate: true,
    }
  },
  mounted() {
    window.eventBus.$on('notify:user_tags', this.handleUserTagsChange);
  },
  methods: {
    clickEntry: function (entry) {
      this.currentMiniToolEntry = entry;
    },
    handleUserTagsChange: function () {
      this.loadMiniToolEntries();
    },
    loadMiniToolEntries: function () {
      if (this.busyLoadingCode === this.loadingCode) return;
      this.$reqGet({
        path: '/minitool/list/get/by/user/organization',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.miniToolEntries = res.data;
            if (this.miniToolEntries.length > 0) {
              if (this.currentMiniToolEntry == null) {
                this.currentMiniToolEntry = this.miniToolEntries[0];
              } else {
                let isCurrentMiniToolDelete = true;
                for (let entry of this.miniToolEntries) {
                  if (entry.id === this.currentMiniToolEntry) {
                    isCurrentMiniToolDelete = false;
                    break;
                  }
                }
                if (isCurrentMiniToolDelete) {
                  this.currentMiniToolEntry = this.miniToolEntries[0];
                }
              }
            } else {
              this.currentMiniToolEntry = null;
            }
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    }
  }
}
</script>

<style scoped>

.minitool-list {
  width: 88px;
}

.minitool-entry {
  margin: 10px;
}

</style>